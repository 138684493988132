/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getSrc } from "gatsby-plugin-image";
import Collapse from "react-bootstrap/Collapse";

// Layout
import SecondaryLanding from "../../layouts/secondary-landing";

// Components
import Breadcrumb from "../breadcrumb/breadcrumb-auto";
import Button from "../custom-widgets/button";
import Icon from "../custom-widgets/icon";
import DailyHours from "../branch-info/daily-hours";
import BranchServices from "../branch-info/branch-services";
import GoogleMapSimple from "../google-map/google-map-simple";
import SEO from "../../components/seo/seo";
import Modal from "react-bootstrap/Modal";
import HolidaysModal from "../holidays-modal";
import NotificationAlert from "../../components/notifications/notification-alert";
import LoanOfficerSearch from "../loan-officers/loan-officer-search";

// Styles
import "./branch.bootstrap.scss";
import styles from "../../pages/commercial-banking/treasury-management-services/express.module.scss";
import BankerHeadshot from "../../components/banker-headshot";

export const query = graphql`
  query spanishBranchQuery($pageURL: String) {
    spanishBranch: strapiBranchLocations(PageURL: { eq: $pageURL }) {
      PageURL
      Title
      BranchId
      YextCode
      MSBookingsBranchName
      Address {
        City
        State
        StreetAddress
        ZipCode
        BranchGeoLocation {
          id
          Lat
          Lng
          Zoom
        }
      }
      PhoneNumber
      EmailAddress
      BranchImageUrl
      BranchServices {
        TellerServices
        WalkUpATM
        DriveThroughATM
        DriveThroughBanking
        SafeDepositBoxes
      }
      LobbyHours {
        AllClosed
        Friday
        Monday
        Saturday
        Sunday
        Thursday
        Tuesday
        Wednesday
      }
      DriveUpHours {
        AllClosed
        Friday
        Monday
        Saturday
        Sunday
        Thursday
        Tuesday
        Wednesday
      }
      SpanishDisruptionNotice
      SEO {
        MetaDescription
        MetaTitle
        Schema {
          address {
            _type
            addressCountry
            addressLocality
            addressRegion
            postalCode
            streetAddress
          }
          _context
          _type
          amenityFeature {
            _type
            hoursAvailable {
              _type
              closes
              dayOfWeek
              opens
            }
            name
            value
          }
          branchCode
          email
          geo {
            _type
            latitude
            longitude
          }
          image
          name
          openingHoursSpecification {
            _type
            closes
            dayOfWeek
            opens
          }
          parentOrganization {
            _type
            logo
            name
            url
          }
          telephone
          url
        }
      }
      OgImageUrl
      branch_state {
        StateCode
        Slug
      }
      loan_officers {
        FirstName
        LastName
        JobTitle
        EmailAddress
        ProfilePhoto {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      mortgage_bankers {
        Active
        FirstName
        LastName
        JobTitle
        EmailAddress
        ProfilePhoto {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    defaultBranchPhoto: file(relativePath: { eq: "branches/wafd-bank-branch-default-1000.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          width: 1200
          transformOptions: { cropFocus: CENTER }
          layout: CONSTRAINED
        )
      }
    }
  }
`;

const SpanishBranchTemplate = ({ location, data }) => {
  const branch = data.spanishBranch;
  const StateCode = branch.branch_state.StateCode;
  const StateName =
    branch.Address.State === "NewMexico" || branch.Address.State === "New Mexico"
      ? "Nuevo Mexico"
      : branch.Address.State;

  // Google Maps Data
  const GMData = {
    lang: "es",
    center: {
      lat: branch.Address.BranchGeoLocation.Lat,
      lng: branch.Address.BranchGeoLocation.Lng
    },
    zoom: 15, //branch.Address.BranchGeoLocation.Zoom
    title: "WaFd Bank " + branch.Title,
    height: "350px",
    width: "100%",
    markers: [
      {
        lat: branch.Address.BranchGeoLocation.Lat,
        lng: branch.Address.BranchGeoLocation.Lng,
        text: branch.Title
      }
    ]
  };
  const pageLocation = { location };

  // 2022 Holidays Modal
  const [showHolidayModal, setShowHolidayModal] = useState(false);
  const handleCloseModal = () => setShowHolidayModal(false);
  const handleShowModal = (e) => {
    e.preventDefault();
    setShowHolidayModal(true);
  };

  // TODO: the case sensitivity of these keys must match what is in Strapi data, too brittle.
  let weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  let now = new Date();

  const [today, setToday] = useState("");
  function getWeekDay(date) {
    let day = date.getDay();
    return weekdays[day];
  }
  useEffect(() => {
    let weekDay = getWeekDay(now);
    setToday(weekDay);
  }, []);

  const todaysLobbyHours = branch.LobbyHours.AllClosed
    ? "Cerrado"
    : branch.LobbyHours[today] !== null
    ? branch.LobbyHours[today]
    : "Desconocido";

  const todaysLobbyHoursHtml = todaysLobbyHours?.match(/closed/i)
    ? "Lobby <strong>Cerrado hoy</strong>"
    : "Lobby Abierto Hoy: <strong>" + todaysLobbyHours + "</strong>";

  const openAccountBtnData = {
    id: "open-account-cta",
    containerClass: "mb-4",
    type: "link",
    class: "btn-primary w-100 w-lg-100 w-sm-auto",
    text: "Abrir una cuenta",
    url: "/es/abrir-cuenta-bancaria-en-linea",
    showIcon: false
  };

  const viewInGoogleMapUrl =
    "https://maps.google.com/maps?daddr=" +
    branch.Address.StreetAddress +
    "%2c" +
    branch.Address.City +
    "%2c" +
    StateCode +
    "%2c" +
    branch.Address.ZipCode;

  // provide the static URLs for og:image meta tag
  const branchOgImage = branch.OgImageUrl
    ? `https://www.wafdbank.com` + branch.OgImageUrl
    : branch.BranchImageUrl
    ? `https://www.wafdbank.com${branch.BranchImageUrl}`
    : `https://www.wafdbank.com` + getSrc(data.defaultBranchPhoto);

  const fullPageUrl = "https://www.wafdbank.com" + pageLocation.location.pathname;

  const metaTitle =
    //Bellevue, WA branch has a completely different metadata as it's from LBS and also it only provides Commercial Services
    StateCode === "CA"
      ? branch.Title + ", California Bank - Cuentas corrientes y de ahorro"
      : branch.PageURL === "/locations/washington/bellevue/central"
      ? "Banca comercial en Central Bellevue, WA"
      : "Ubicación de sucursal de banco en " + branch.Title + ", " + StateName;
  const metaDescription =
    StateCode === "CA"
      ? "Bienvenido a WaFd Bank of California en " +
        branch.Title +
        " (formalmente Luther Burbank Savings). Ofrecemos cuentas corrientes, de ahorro y de CD y más en " +
        branch.Title +
        ", CA."
      : branch.PageURL === "/locations/washington/bellevue/central"
      ? "Bienvenido a WaFd Bank en Central Bellevue, WA (formalmente Luther Burbank Savings). Nuestra sucursal de Central Bellevue ofrece servicios de banca comercial y más."
      : "En nuestra sucursal de banco en " +
        branch.Title +
        ", " +
        StateName +
        " le atenderemos con gusto. Encuentre la sucursal de WaFd Bank más cercana a usted y visítenos.";

  const SEOData = {
    title: metaTitle,
    meta: [
      {
        name: "title",
        property: "og:title",
        content: metaTitle
      },
      {
        name: "description",
        property: "og:description",
        content: metaDescription
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:url",
        content: fullPageUrl
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        property: "og:image",
        content: branchOgImage
      }
    ]
  };

  function translateLobbyHours() {
    let spanishLobbyHours = { ...branch.LobbyHours };
    for (const prop in spanishLobbyHours) {
      if (spanishLobbyHours[prop] === "Closed") {
        spanishLobbyHours[prop] = "Cerrada";
      }
    }
    return spanishLobbyHours;
  }

  function translateDriveUpHours() {
    let spanishDriveUpHours = { ...branch.DriveUpHours };
    for (const prop in spanishDriveUpHours) {
      if (spanishDriveUpHours[prop] === "Closed") {
        spanishDriveUpHours[prop] = "Cerrada";
      }
    }
    return spanishDriveUpHours;
  }

  const accordionData = [
    {
      title: "Servicios",
      component: (
        <BranchServices
          {...{
            services: branch.BranchServices,
            lang: "es"
          }}
        />
      ),
      condition: branch.BranchServices
    },
    {
      title: "Horario del lobby",
      component: (
        <>
          <DailyHours lang={"es"} {...translateLobbyHours()} />
          <p>
            <button
              className={`anchor-link text-decoration-none ${styles.buttonTransparent}`}
              onClick={handleShowModal}
            >
              <Icon name="calendar" class="mr-2" />
              Días festivos 2024
            </button>
          </p>
        </>
      ),
      condition: branch.LobbyHours
    },
    {
      title: "Horas de Autoservicio",
      component: <DailyHours lang={"es"} {...translateDriveUpHours()} />,
      condition: branch.DriveUpHours
    }
  ];

  const initialState = Array(accordionData.length).fill(false);

  const [collapseClass, setCollapseClass] = useState(initialState);
  function updateCollapseState(index) {
    const newState = [...initialState];
    newState[index] = !collapseClass[index];
    setCollapseClass(newState);
  }

  // console.log(
  //   `Found ${branch.loan_officers.length} Personal Bankers and ${branch.mortgage_bankers.length} Mortgage Bankers`
  // );
  let loanOfficers = [...branch.loan_officers, ...branch.mortgage_bankers.filter((mb) => mb.Active === true)];

  // console.log(`Found ${loanOfficers.length} Loan Officers (total)`);

  loanOfficers.forEach(function (loanOfficer) {
    if (loanOfficer.JobTitle === "BranchManager") {
      loanOfficer.JobTitle = "Gerente de Sucursal";
    } else if (loanOfficer.JobTitle === "BranchManagerTrainee") {
      loanOfficer.JobTitle = "Gerente entrenando de Sucursal";
    } else if (loanOfficer.JobTitle === "AssistantBranchManager") {
      loanOfficer.JobTitle = "Asistente de Gerente Sucursal";
    } else if (loanOfficer.JobTitle === "PersonalBanker") {
      loanOfficer.JobTitle = "Banquero Personal";
    } else if (loanOfficer.JobTitle === "MortgageBanker") {
      loanOfficer.JobTitle = "Banquero Hipotecario";
    } else if (loanOfficer.JobTitle === "LoanOfficer") {
      loanOfficer.JobTitle = "Oficial de préstamo";
    } else if (loanOfficer.JobTitle === "FirstTimeHomeBuyerSpecialist") {
      loanOfficer.JobTitle = "Especialista de Compras de Casa por Primera Vez";
    }
  });

  const branchManager = loanOfficers.find((lo) => lo.JobTitle === "Gerente de Sucursal");
  if (loanOfficers && branchManager) {
    loanOfficers = loanOfficers.filter((n) => n.JobTitle !== "Gerente de Sucursal");
    loanOfficers.unshift(branchManager);
  }

  const [showLOModalMortgage, setShowLOModalMortgage] = useState(false);
  const handleCloseLOModalMortgage = () => setShowLOModalMortgage(false);
  const handleShowLOModalMortgage = () => {
    setShowLOModalMortgage(true);
  };

  const [showLOModalHomeEquity, setShowLOModalHomeEquity] = useState(false);
  const handleCloseLOModalHomeEquity = () => setShowLOModalHomeEquity(false);
  const handleShowLOModalHomeEquity = () => {
    setShowLOModalHomeEquity(true);
  };

  const iconBlocksData = [
    {
      text: "Abra una cuenta personal en línea",
      src: "/images/icons/personal-accounts-icon.svg",
      url: "/es/abrir-cuenta-bancaria-en-linea"
    },
    {
      text: "Conozca qué tarjeta de crédito de WaFd Bank funciona mejor para usted.",
      src: "/images/icons/credit-cards-icon-96.svg",
      url: "/es/banca-personal/tarjetas-de-credito"
    },
    {
      text: "¿Ya tienes una cuenta? Regístrese para acceder en línea",
      src: "/images/icons/enroll-online-access-icon.svg",
      url: "https://online.wafdbank.com/link/register?application=OLB",
      externalLink: true
    },
    {
      text: "Solicitar un préstamo hipotecario",
      src: "/images/icons/house-with-heart.svg",
      onClick: handleShowLOModalMortgage
    },
    {
      text: "Solicitar un préstamo sobre el valor líquido de la vivienda",
      src: "/images/icons/heloc-loans-icon-96.svg",
      onClick: handleShowLOModalHomeEquity
    },
    {
      text: "¿Comprar o refinanciar una vivienda? ¡Póngase en contacto con el representante bancario de su sucursal local!",
      src: "/images/icons/home-loans-icon.svg",
      url: "/es/banca-personal/contacte-a-un-asesor-hipotecario"
    }
  ];

  const hasDisruptionNotice =
    branch && branch.SpanishDisruptionNotice && branch.SpanishDisruptionNotice.trim().length !== 0;

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <Breadcrumb {...{ location }} />
      {/* Disruption Notice */}
      {hasDisruptionNotice && (
        <section className="container pb-0">
          <NotificationAlert
            type="warning"
            id="branch-disruption-notification-alert"
            class="mb-0"
            bodyText={branch.SpanishDisruptionNotice}
          />
        </section>
      )}
      {/* Branch Info Section */}
      <section className="container">
        <div className="row">
          <div className="col-md-6">
            <h1 className="mb-3">WaFd Bank {branch.Title}</h1>
            <div className="row">
              <div className={branch.MSBookingsBranchName ? "col-lg-6" : "col-12"}>
                {/* Branch Address */}
                <div className="d-flex mb-3">
                  <Icon name="map-marker-alt" lib="far" class="text-primary mr-2 mt-1" />
                  <a className="text-decoration-none" href={viewInGoogleMapUrl} id="branch-address-cta">
                    {branch.Address.StreetAddress}, {branch.Address.City},{" "}
                    {branch.Address.State === "NewMexico" ? "Nuevo Mexico" : branch.Address.State}{" "}
                    {branch.Address.ZipCode}
                  </a>
                </div>
                {/* Today Branch Hours */}
                <div className="mb-3 d-flex">
                  <Icon name="clock" lib="far" class="text-primary mr-2 mt-1" />
                  <span
                    id="today-branch-hours"
                    dangerouslySetInnerHTML={{
                      __html: todaysLobbyHoursHtml
                    }}
                  ></span>
                </div>
                {/* Branch Phone Number */}
                <div className="mb-3">
                  <Icon name="phone" lib="far" class="text-primary mr-2" />
                  <a className="text-decoration-none" id="branch-phone-cta" href={`tel:${branch.PhoneNumber}`}>
                    {branch.PhoneNumber}
                  </a>{" "}
                  (Teléfono)
                </div>
              </div>
              <div className={`col-lg-6 ${branch.MSBookingsBranchName ? "order-1 order-lg-0" : "order-1"}`}>
                <Button {...openAccountBtnData} />
              </div>
              {/* Branch Email Address */}
              <div className="col-12 mb-3">
                <Icon name="envelope" lib="far" class="text-primary mr-2" />
                <a className="text-decoration-none" id="branch-email-cta" href={`mailto:${branch.EmailAddress}`}>
                  {branch.EmailAddress}
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <GoogleMapSimple {...GMData} />
          </div>
        </div>
      </section>
      {/* Branch Services Section */}
      <section className="bg-light pb-0">
        <div className="container">
          <div className="row">
            {/* Branch or Default Photo */}
            <div className="col-md-4">
              {branch.BranchImageUrl ? (
                <img
                  src={branch.BranchImageUrl}
                  className="border-radius-12 mw-100"
                  alt={`WaFd Bank in ${branch.Address.City}, ${branch.Address.State} #${branch.BranchId} - Washington Federal.`}
                />
              ) : null}
              {/* Branch Services */}
              <div className="d-none d-md-block">
                <div className="branchServices my-3">
                  <BranchServices
                    {...{
                      services: branch.BranchServices,
                      lang: "es"
                    }}
                  />
                </div>
              </div>
            </div>
            {/* Lobby Hours */}
            <div className="d-none d-md-block col-md-4">
              <div className="lobbyHours pr-lg-5">
                <h3>Horario del lobby</h3>
                <DailyHours lang={"es"} {...translateLobbyHours()} />
              </div>
              <p>
                <button
                  className={`anchor-link text-decoration-none ${styles.buttonTransparent}`}
                  onClick={handleShowModal}
                >
                  <Icon name="calendar" lib="fas" class="mr-2" />
                  Días festivos 2024
                </button>
              </p>
            </div>
            {/* Current Year Holidays Modal */}
            <Modal show={showHolidayModal} onHide={handleCloseModal}>
              <Modal.Header className="border-0" closeButton></Modal.Header>
              <Modal.Body className="pt-0">
                <HolidaysModal />
              </Modal.Body>
              <Modal.Footer>
                <a
                  href="/#"
                  className="text-left text-black font-weight-bold"
                  style={{
                    cursor: "pointer"
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    handleCloseModal();
                  }}
                >
                  Cerrar
                </a>
              </Modal.Footer>
            </Modal>
            {/* Drive Thru Hours */}
            <div className="d-none d-md-block col-md-4">
              {branch.BranchServices.DriveThroughBanking && (
                <div className="driveUpHours border-left pl-3 pl-lg-5">
                  <h3>Horas de Autoservicio</h3>
                  <DailyHours lang={"es"} {...translateDriveUpHours()} />
                </div>
              )}
            </div>
          </div>
          {/* Branch Services Mobile Section */}
          {/* Mobile Accordions */}
          <div className="mt-4 d-block d-md-none" id="branch-accordions">
            {accordionData.map((accordion, index) => {
              return (
                accordion.condition && (
                  <div className={`${"border-top"}`} key={index}>
                    <button
                      id={`accordion-${index}`}
                      onClick={(e) => {
                        updateCollapseState(index);
                      }}
                      className={`border-0 w-100 d-flex align-items-center bg-light `}
                      aria-controls={`accordion-${index}`}
                      aria-expanded={collapseClass[index]}
                      aria-labelledby={`accordion-${index}`}
                    >
                      <Icon class="fa-2x text-success mx-2 my-1" name={`${collapseClass[index] ? "minus" : "plus"}`} />
                      {accordion.title}
                    </button>
                    <Collapse in={collapseClass[index]}>
                      <div className="py-3 ml-4 pl-3">{accordion.component}</div>
                    </Collapse>
                  </div>
                )
              );
            })}
          </div>
        </div>
      </section>
      {/* Branch Manager and Loan Officers */}
      {loanOfficers.length > 0 && (
        <section className="container">
          <h2 className="font-weight-semi-bold">Meet our {branch.Title} Team</h2>
          <div className="row">
            {loanOfficers &&
              loanOfficers.map((loanOfficer, index) => {
                return (
                  <div className="col-sm-6 col-lg-4 mb-5" key={index}>
                    <div className="row">
                      <div className="col-auto mb-3">
                        <BankerHeadshot
                          email={loanOfficer.EmailAddress}
                          aria-label={`WaFd Bank ${branch.Title} Branch Manager ${loanOfficer.FirstName} ${loanOfficer.FirstName}`}
                        />
                      </div>
                      <div className="col">
                        <h5 className="mb-2">
                          {loanOfficer.FirstName} {loanOfficer.LastName}
                        </h5>
                        <p className="mb-3">{loanOfficer.JobTitle}</p>
                        <a href={`mailto:${loanOfficer.EmailAddress}`} className="btn btn-link d-none d-sm-block">
                          Email {loanOfficer.FirstName}{" "}
                          <Icon name="envelope-open-text" lib="far" class="text-primary ml-2" />
                        </a>
                      </div>
                      <div className="col d-block d-sm-none">
                        <a href={`mailto:${loanOfficer.EmailAddress}`} className="btn btn-link btn-block">
                          Email {loanOfficer.FirstName}{" "}
                          <Icon name="envelope-open-text" lib="far" class="text-primary ml-2" />
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </section>
      )}

      {StateCode === "CA" ? (
        <section className="container">
          <h2 className="text-success">WaFd Bank {branch.Title} en línea</h2>
          <p>
            Ingrese a nuestra sucursal y verá que este es el banco de su vecindario, con banqueros que se preocupan por
            saber su nombre y ayudarlo a encontrar el éxito financiero aquí mismo en la comunidad. Desde cuentas{" "}
            <Link to="/es/banca-personal/cuenta-de-cheques" id="checking-accounts-page-link">
              corrientes gratuitas
            </Link>{" "}
            hasta{" "}
            <Link to="/es/banca-personal/tarjetas-de-credito" id="credit-card-page-link">
              tarjetas de crédito de recompensas en efectivo
            </Link>
            , WaFd Bank está aquí para todas sus necesidades bancarias.
          </p>
          <p>
            O si está buscando una excelente experiencia de banca móvil y en línea, puede abrir una cuenta o un banco
            con nosotros en cualquier momento y lugar, desde su computadora, tableta o teléfono inteligente.
          </p>
        </section>
      ) : (
        <section className="bg-light">
          <div className="container">
            <h2 className="text-success">WaFd Bank {branch.Title} en línea</h2>
            <p>
              Ingrese a nuestra sucursal y verá que este es el banco de su vecindario, con banqueros que se preocupan
              por saber su nombre y ayudarlo a encontrar el éxito financiero aquí mismo en la comunidad. Desde cuentas{" "}
              <Link to="/es/banca-personal/cuenta-de-cheques" id="checking-accounts-page-link">
                corrientes gratuitas
              </Link>{" "}
              e{" "}
              <Link to="/es/banca-personal/prestamos-hipotecarios" id="home-loans-page-link">
                hipotecas
              </Link>{" "}
              hasta{" "}
              <Link to="/es/banca-personal/tarjetas-de-credito" id="credit-card-page-link">
                tarjetas de crédito de recompensas en efectivo
              </Link>
              , WaFd Bank está aquí para todas sus necesidades bancarias.
            </p>
            <p>
              O si está buscando una excelente experiencia de banca móvil y en línea, puede abrir una cuenta o un banco
              con nosotros en cualquier momento y lugar, desde su computadora, tableta o teléfono inteligente.
            </p>
          </div>
        </section>
      )}

      {/* Icon Blocks */}
      <section id="branch-icon-blocks-container">
        <div className="container">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 no-gutters">
            {iconBlocksData.map((data, index) => (
              <div
                className="d-flex d-sm-block position-relative px-sm-3 text-sm-center align-items-center"
                key={index}
                id={`branch-icon-block-${index + 1}`}
              >
                {data.onClick ? (
                  <button
                    id={`branch-icon-block-${index + 1}-link`}
                    onClick={data.onClick}
                    className="btn-anchor-link stretched-link"
                  >
                    <span className="sr-only">{data.text}</span>
                  </button>
                ) : data.externalLink ? (
                  <a
                    id={`branch-icon-block-${index + 1}-link`}
                    className="stretched-link"
                    href={data.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="sr-only">{data.text}</span>
                  </a>
                ) : (
                  <Link id={`branch-icon-block-${index + 1}-link`} className="stretched-link" to={data.url}>
                    <span className="sr-only">{data.text}</span>
                  </Link>
                )}
                <img src={data.src} className="mb-3 mr-3 mr-sm-0" alt={data.text} />
                <p className="font-weight-bold text-black">
                  {data.text}
                  <Icon name="arrow-right" class="ml-2 text-primary" lib="fas" />
                </p>
              </div>
            ))}
          </div>
        </div>
        <LoanOfficerSearch
          isModalSearch={true}
          show={showLOModalMortgage}
          handleClose={handleCloseLOModalMortgage}
          loanType="Home Loan"
        />
        <LoanOfficerSearch
          isModalSearch={true}
          show={showLOModalHomeEquity}
          handleClose={handleCloseLOModalHomeEquity}
          loanType="HELOC" // can use both HELOC and HELOAN
        />
      </section>
    </SecondaryLanding>
  );
};

SpanishBranchTemplate.propTypes = {
  branch: PropTypes.object
};

export default SpanishBranchTemplate;
